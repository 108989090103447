<template>
  <div class="mt-16">
    <help-dialog
      :path="headingPath"
      tag="h3"
      css-classes="mb-2"
    ></help-dialog>

    <data-table
      table-name="versions.table"
      :data-table="dataTable"
      :actions="actions"
      :search-action="searchAction"
    >
      <template #item.validFrom="{ item }">
        <span :class="{ 'text-decoration-line-through': isVersionOverridden(item) }">
          <formatted-date :date="item.validFrom" time></formatted-date>

          <v-tooltip
            v-if="isVersionOverridden(item)"
            max-width="300"
            right
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-2"
                color="primary"
                small
              >
                {{ $icons.mdiHelpCircle }}
              </v-icon>
            </template>
            <span>{{ overriddenHint }}</span>
          </v-tooltip>
        </span>
      </template>

      <template #item.uuid="{ item }">
        <copy
          :display="shortenUuid(item.uuid)"
          :text="item.uuid"
        ></copy>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-4">
          <embedded-object
            type="application/pdf"
            width="100%"
            :source="getFileUrl(item.fileUuid)"
            :download="getFileUrl(item.fileUuid, true)"
          >
            {{ item.fileUuid }}
          </embedded-object>

          <template v-if="item.comment.length > 0">
            <v-divider class="my-4"></v-divider>

            <v-container
              fluid
              class="pa-0 mx-0 mb-0 mt-2"
            >
              <v-row>
                <v-col>
                  <span class="font-weight-bold">{{ item.createdUser.preferredUsername }}</span>
                </v-col>

                <v-col class="text-right body-2">
                  <formatted-date :date="item.createdOn"></formatted-date>
                </v-col>
              </v-row>

              <p class="mt-2 body-2">{{ item.comment }}</p>
            </v-container>
          </template>
        </td>
      </template>
    </data-table>

    <template v-if="activeDocumentComments.length > 0">
      <h3 class="mt-16">{{ commentsHeading }}</h3>

      <v-container
        fluid
        class="pa-0 mx-0 mb-0 mt-2"
        v-for="({ comment, createdUser, createdOn }, i) in activeDocumentComments"
        :key="i"
      >
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ createdUser.preferredUsername }}</span>
          </v-col>

          <v-col class="text-right body-2">
            <formatted-date :date="createdOn"></formatted-date>
          </v-col>
        </v-row>

        <p class="mt-2 body-2">{{ comment }}</p>
      </v-container>
    </template>

    <v-dialog
      width="600"
      v-model="deleteDialog"
    >
      <v-card
        class="px-4 py-4"
        v-if="deleteDocumentItem"
      >
        <v-card-title>{{ deleteDialogTitle }}</v-card-title>

        <v-card-actions>
          <v-btn text color="primary" @click="resetDeleteDialog">{{ deleteDialogCancelText }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="confirmDelete">{{ deleteDialogConfirmText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isPast } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

import { createValidDateFromApi } from '@/helpers/datetime.helper'
import { getFileUrl } from '@/api/files.api'
import { shortenUuid } from '@/helpers/utility.helper'

import Copy from '../Copy'
import DataTable from '../DataTable'
import EmbeddedObject from '../EmbeddedObject'
import FormattedDate from '../FormattedDate'
import HelpDialog from '../HelpDialog'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

export default {
  name: 'DocumentVersions',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    Copy,
    DataTable,
    EmbeddedObject,
    FormattedDate,
    HelpDialog
  },
  data () {
    return {
      getFileUrl,
      shortenUuid,
      primaryField: 'createdOn',
      headers: [
        { value: 'validFrom' },
        { value: 'uuid' },
        { value: 'actions', sortable: false, align: 'end' }
      ],
      searchTerm: '',
      deleteDocumentItem: null,
      deleteDialog: false
    }
  },
  methods: {
    ...mapActions('documents', ['deleteDocumentVersion']),
    searchHandler (term) {
      this.searchTerm = term
    },
    openFile (uuid) {
      const url = getFileUrl(uuid, true)

      window.open(url, '_blank')
    },
    handleDelete (item) {
      this.deleteDocumentItem = item
      this.deleteDialog = true
    },
    resetDeleteDialog () {
      this.deleteDialog = false
      this.deleteDocumentItem = null
    },
    confirmDelete () {
      const handlers = {
        '2xx': () => this.deleteSuccessMessage,
        '4xx': () => this.deleteErrorMessage,
        '5xx': () => this.deleteErrorMessage
      }
      this.deleteDocumentVersion({
        uuid: this.deleteDocumentItem.documentHandleUuid,
        versionUuid: this.deleteDocumentItem.uuid,
        handlers
      })
      this.resetDeleteDialog()
    },
    isVersionOverridden (version) {
      if (!this.activeDocumentVersion) {
        return false
      }

      if (version.uuid === this.activeDocumentVersion.uuid) {
        return false
      }

      const versionValidFrom = createValidDateFromApi(version.validFrom)
      const versionCreatedOn = createValidDateFromApi(version.createdOn)

      const activeVersionValidFrom = createValidDateFromApi(this.activeDocumentVersion.validFrom)
      const activeVersionCreatedOn = createValidDateFromApi(this.activeDocumentVersion.createdOn)

      if (versionValidFrom < activeVersionValidFrom) {
        return false
      }

      return versionCreatedOn <= activeVersionCreatedOn
    },
    isDeleteButtonDisabled ({ uuid, validFrom }) {
      if (!this.isAdmin && !this.isManager) {
        return true
      }

      if (this.activeDocumentVersion && this.activeDocumentVersion.uuid === uuid) {
        return true
      }

      const validFromDate = createValidDateFromApi(validFrom)

      return isPast(validFromDate)
    }
  },
  computed: {
    ...mapGetters('documents', ['activeDocumentVersions', 'activeDocumentComments', 'activeDocumentVersion']),
    dataTable () {
      return {
        headers: this.headers,
        items: this.activeDocumentVersions,
        loading: this.loading,
        showSelect: false,
        itemKey: this.primaryField,
        sortBy: 'updatedOn',
        sortDesc: true,
        disablePagination: true,
        hideDefaultFooter: true,
        options: {},
        search: this.searchTerm,
        showExpand: true,
        singleExpand: false,
        itemClass: (item) => {
          if (this.activeDocumentVersion && this.activeDocumentVersion.uuid === item.uuid) {
            return 'light-green lighten-4'
          }

          return ''
        }
      }
    },
    actions () {
      return [
        { icon: this.$icons.mdiDelete, handler: this.handleDelete, disabled: this.isDeleteButtonDisabled, tooltip: this.deleteButtonTooltip },
        { icon: this.$icons.mdiDownload, handler: ({ fileUuid }) => { this.openFile(fileUuid) }, tooltip: this.downloadButtonTooltip }
      ]
    },
    componentTranslationKey () {
      return `${this.pageTranslationKey}.components.versions`
    },
    tableTranslationKey () {
      return `${this.componentTranslationKey}.table`
    },
    headingPath () {
      return `${this.componentTranslationKey}.heading`
    },
    searchAction () {
      return {
        label: this.$t(`${this.tableTranslationKey}.search.label`),
        hint: this.$t(`${this.tableTranslationKey}.search.hint`),
        handler: this.searchHandler
      }
    },
    deleteDialogTitle () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.title`, {
        document: this.deleteDocumentItem?.name ?? ''
      })
    },
    deleteDialogCancelText () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.buttons.cancel`)
    },
    deleteDialogConfirmText () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.buttons.confirm`)
    },
    commentsHeading () {
      return this.$t(`${this.componentTranslationKey}.comments.heading`)
    },
    deleteButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.delete`)
    },
    downloadButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.download`)
    },
    deleteErrorMessage () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.messages.error`)
    },
    deleteSuccessMessage () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.messages.success`)
    },
    overriddenHint () {
      return this.$t(`${this.tableTranslationKey}.overriddenHint`)
    }
  },
  inject: [
    'pageTranslationKey'
  ]
}
</script>
