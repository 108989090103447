<template>
  <div>
    <div class="mb-8">
      <page-header :heading="pageHeading" :sub-heading="pageSubHeading"></page-header>
    </div>

    <component-guard
      :roles="['admin', 'manager']"
    >
      <document-form></document-form>
    </component-guard>

    <document-management></document-management>
  </div>
</template>

<script>
import DocumentForm from '@/components/documents/DocumentForm'
import DocumentManagement from '@/components/documents/DocumentManagement'
import PageHeader from '@/components/PageHeader'

import i18n from '@/mixins/i18n.mixin'
import Page from '@/mixins/page.mixin'

export default {
  name: 'Documents',
  components: {
    DocumentForm,
    DocumentManagement,
    PageHeader
  },
  mixins: [
    i18n,
    Page
  ]
}
</script>
