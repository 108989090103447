<template>
  <div>
    <help-dialog
      :path="headingPath"
      tag="h3"
      css-classes="mb-2"
    ></help-dialog>

    <form-builder
      :config="config"
      :data="form.data"
      :errors="form.errors"
      :rules="form.rules"
      :transformers="form.transformers"
      translation-base="components.versions"
      @submit="handleSubmit"
      @reset="handleReset"
    >
      <template #fileType.fileUuid.after="{ uuid }">
        <template v-if="uuid">
          <div class="mt-4">
            <object
              :data="getFileUrl(uuid)"
              type="application/pdf"
              width="100%"
              height="300"
            >
              {{ uuid }}
            </object>
          </div>
        </template>
      </template>

      <template #form.footer="{ submit, submitText, valid }">
        <v-btn color="primary" @click="submit" :disabled="!valid">{{ submitText }}</v-btn>
      </template>
    </form-builder>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

import { createValidDateStringFromPicker } from '@/helpers/datetime.helper'
import { getFileUrl } from '@/api/files.api'
import { required, minDate } from '@/helpers/validators.helpers'
import { getHttpValidationErrorDetails } from '@/helpers/api.helper'

import FormBuilder from '../form/FormBuilder'
import HelpDialog from '../HelpDialog'

export default {
  name: 'AddDocumentVersionForm',
  components: {
    FormBuilder,
    HelpDialog
  },
  data () {
    return {
      getFileUrl,
      form: {
        data: {
          validFrom: '',
          fileUuid: '',
          comment: ''
        },
        errors: {
          validFrom: '',
          fileUuid: '',
          comment: ''
        },
        rules: {
          name: [
            required
          ],
          fileUuid: [
            required
          ],
          validFrom: [
            required,
            [minDate, { min: new Date(), allowSameDate: true }]
          ]
        },
        transformers: {
          validFrom: (value) => createValidDateStringFromPicker(value, false, true)
        }
      }
    }
  },
  methods: {
    ...mapActions('documents', ['createDocumentVersion']),
    ...mapActions('messageQueue', ['queueSuccess']),
    async handleSubmit ({ validFrom, fileUuid, comment }) {
      await this.createDocumentVersion({
        validFrom,
        fileUuid,
        comment,
        uuid: this.activeDocumentUuid,
        handlers: {
          '2xx': () => {
            this.resetForm()

            return this.$tp('components.versions.form.messages.success')
          },
          422: (response) => {
            const errors = getHttpValidationErrorDetails(response)

            if (errors) {
              errors.forEach(({ field, message }) => {
                this.$set(this.form.errors, field, message)
              })
            }

            return this.$t('global.form.errorMessages.invalid')
          },
          '4xx': () => this.$t('global.form.errorMessages.invalid')
        }
      })
    },
    handleReset (field) {
      this.$set(this.form.errors, field, '')
    },
    resetForm () {
      this.form.data = {
        fileUuid: '',
        validFrom: '',
        comment: ''
      }
    }
  },
  computed: {
    ...mapGetters('documents', ['activeDocumentUuid']),
    headingPath () {
      return 'components.versions.form.heading'
    },
    config () {
      return [
        [
          [
            {
              name: 'validFrom',
              component: 'v-date-picker',
              props: {
                min: format(new Date(), 'yyyy-MM-dd')
              }
            },
            {
              name: 'fileUuid',
              component: 'file-type',
              props: {
                accept: ['application/pdf'],
                maxSize: 524288 // 512 KiB
              }
            },
            {
              name: 'comment',
              component: 'v-textarea'
            }
          ]
        ]
      ]
    }
  },
  inject: [
    '$tp'
  ]
}
</script>
